import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Col, Row, Spin, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faArrowRightFromBracket, faExchange } from '@fortawesome/free-solid-svg-icons';
import { SettingOutlined } from '@ant-design/icons';

import { getHireContractRequest } from '../../core/order/orderActions';
import OrderDetails from '../../components/OrderDetails';
import HireItems from '../../components/HireItems';

import './style.css';
import OrderActions from '../../components/OrderActions';
import Main from '../../components/layout/Main';
import OffHireReminder from '../../components/OffHireReminder';

const HireContract = () => {
    const { contractNo } = useParams();
    const dispatch = useDispatch();
    const { hireContract, isFetchingHireContract } = useSelector(state => state.order);

    const [selectedAction, setSelectedAction] = useState('none');

    useEffect(() => {
        dispatch(getHireContractRequest(contractNo));
    }, []);

    return !isFetchingHireContract ? (

        <Main>
            <OrderDetails
                info={{
                    contractNo: hireContract.contractNo,
                    poNumber: hireContract.poNumber,
                    onHireDate: hireContract.onHireDate,
                    offHireDate: hireContract.offHireDate,
                    status: hireContract.status,
                }}
                customerContact={hireContract.customerContact}
                siteAddress={hireContract.siteAddress}
                orderItemsComponent={<HireItems siteId={hireContract?.siteAddress?.id} />}
                transport={hireContract.transport} />
            <Row justify="end" style={{ marginTop: '20px' }}>
                <Col span={12}>
                    <Row justify={hireContract.status === 'On Hire' ? 'space-between' : 'end'} align="middle">
                        {hireContract.status === 'On Hire' && (
                            <>
                                {hireContract.webId && (
                                    <>
                                        <div
                                            data-order-id={hireContract.id}
                                            data-web-id={hireContract.contractNo}
                                            className="off-hire-reminder-action">
                                            <Tooltip title="Off-Hire Reminder">
                                                <FontAwesomeIcon
                                                    icon={faBell}
                                                    onClick={() => setSelectedAction('off-hire-reminder')}
                                                    style={{ fontSize: '30px', color: '#da0812', cursor: 'pointer' }} />
                                            </Tooltip>
                                        </div>
                                        <Tooltip title="Off-Hire">
                                            <FontAwesomeIcon icon={faArrowRightFromBracket} onClick={() => setSelectedAction('off-hire')} style={{ fontSize: '30px', color: '#da0812', cursor: 'pointer' }} />
                                        </Tooltip>
                                    </>
                                )}
                                <Tooltip title="Change PO Number">
                                    <SettingOutlined onClick={() => setSelectedAction('change-po-number')} style={{ fontSize: '30px', color: '#da0812' }} />
                                </Tooltip>
                                <Tooltip title="Request Exchange">
                                    <FontAwesomeIcon icon={faExchange} onClick={() => setSelectedAction('request-exchange')} style={{ fontSize: '30px', color: '#da0812', cursor: 'pointer' }} />
                                </Tooltip>
                            </>
                        )}

                        <Button type="primary" size="large" onClick={() => setSelectedAction('order-again')}>Order Again</Button>
                    </Row>
                </Col>
            </Row>
            <OffHireReminder />
            <OrderActions
                setSelectedAction={setSelectedAction}
                selectedAction={selectedAction}
                setSelectedRecord={() => {}}
                selectedRecord={hireContract} />
        </Main>
    ) : (
        <Row>
            <Col>
                <Spin />
            </Col>
        </Row>
    );
};

export default HireContract;

import axios from 'axios';
import qs from 'qs';
import lockr from 'lockr';
import { isUndefined, isEmpty, isObject } from 'underscore';

// load mock data if enabled
import './mock';

const {
    API_URL,
    BACKEND,
    GOOGLE_MAPS_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

// ----- Helpers ----- //
/**
 * decode base64 string and convert to json
 * @param str
 * @returns {any}
 */
const decodeBase64 = (str) => {
    const decodedString = atob(str);
    return JSON.parse(decodedString);
};

// Get Api Url
const getApiUrl = () => `${API_URL}/api`;

// Get hostname url
const getBaseUrl = () => API_URL;

// defaults for axios
const api = axios.create({
    baseURL: getApiUrl(),
});

// Get locational data
const locationData = name => axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${name}&key=${GOOGLE_MAPS_KEY}`);

// Format params for api call
const formatParams = (payload, key) => {
    let params = payload;
    if (!isObject(payload)) {
        params = {};
        params[key || 'id'] = payload;
    }

    return params;
};

// ----- Auth Key Helpers -----//

/**
 * Get Bearer token from storage
 * @return string
 */

const getAuthKey = () => document.cookie.replace(/(?:(?:^|.*;\s*)scorchToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
// eslint-disable-next-line max-len
// const getAuthKey = () => 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzljOGNjODRiZWM0NTMzNTMxNDI1MDhkNWQxZWI5NzY3ODIyMGFmNWYyMTY1NjQzNTU5MWE3ZjMzODE5M2ZmNmE5YjZiZWU4YmZiNTNjYWIiLCJpYXQiOjE2OTUwNTQxMzEuMjU1MjI1LCJuYmYiOjE2OTUwNTQxMzEuMjU1MjMxLCJleHAiOjE3MjY2NzY1MzEuMTA0NDgzLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.YSPfNBU1AdHjc7vHfr56KfIknnHRDg4cQYyKZymZlN6i5BxZDQg6_Jx5OgIJsVGY1LGKniUkmRQBlArPExUA5EnLB4YyTpsNt7iIMcvnHxIT5hggI_ngSRCs8X7fmJ84CYx2ohrX1F0jilFHhIxvPAfp1dBpPuJVDxN-buAVC2W9mJpQ0YvYdDxNM1DPkndb7HnojwFzUDLF0SETAGiA-ihYOWXGtgFtnHWgllXvcwFnQPMU6XNs06gjNDPqmtrU0_NriMn9uxygX4ElPtUCG4i7TX319a3cc-094079j_JapDN6WCpk1jYUKy-bQQZ4HFAMyJMipqCWonpiO67p_3jxUsTAcfRGmZE5J_p3XP8_4LoJZ_ltTnjGloZL9oAf_O4EcbulWFjyEKNW8kkCf9cuGZ0frV_Kpt6OwHC7l1z5eL33U2GhRFz_76GAyPOwxCTvkefwqSzCOZg3RTCU01YbzjQD-YBP7vdZklXHZEBZdBtW-kmt_8iLWGDHA5Epm285z4Yhek2gh2PTLOTB6HGBebbcoDBTVXBzSNSUL_dLUl0OWAFKNbgCO479rETQ-sMAukoioRKxk4nVgDLfHsSeXb9gFZ1ASQ7SPiB7wfz7ToL5NpaAB6LKdTLKKQynumQOk9EWaf4EIzSXyECK5KgLmsArYXiJvGLNXOydXeU';

/**
 * Get token expiry from cookie
 * @returns {string}
 */
const getTokenExpires = () => document.cookie.replace(/(?:(?:^|.*;\s*)scorchTokenExpiry\s*=\s*([^;]*).*$)|^.*$/, '$1');
// const getTokenExpires = () => '2030-03-31 00:00:00';

/**
 * Get customer id from cookie
 * @returns {number}
 */
const getCustomerIdFromCookie = () => document.cookie.replace(/(?:(?:^|.*;\s*)scorchAccountId\s*=\s*([^;]*).*$)|^.*$/, '$1');
// const getCustomerIdFromCookie = () => 12380; // 12654

/**
 * Get Wordpress user id from cookie
 * @returns {number}
 */
const getWordpressUserIdFromCookie = () => document.cookie.replace(/(?:(?:^|.*;\s*)scorchWpUser\s*=\s*([^;]*).*$)|^.*$/, '$1');
// const getWordpressUserIdFromCookie = () => 34529;

/**
 * Get Wordpress user id from cookie
 * @returns {number}
 */
const getUserRoleAndPermissionsFromCookie = () => {
    let data = document.cookie.replace(/(?:(?:^|.*;\s*)scorchUserContext\s*=\s*([^;]*).*$)|^.*$/, '$1');
    data = decodeURIComponent(data);
    if (data) {
        return decodeBase64(data);
    }
    return null;
};

/**
 * Get header object for auth token
 * @return object
 */
const getAuthHeaders = () => ({ Authorization: `Bearer ${getAuthKey()}` });

// ----- Api Functions ----- //

function fetchApi(opts, headers) {

    let data = (opts.method.toUpperCase() === 'GET') ? null : opts.body;
    const responseType = opts.responseType ? opts.responseType : null;
    let laravelHeaders = {};

    if (BACKEND === 'yii') {
        data = data ? qs.stringify(data) : null;
    } else {
        laravelHeaders = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        };
    }

    const options = {
        method: opts.method,
        url: opts.url,
        params: opts.params,
        headers: laravelHeaders,
        responseType,
    };

    if (opts.method.toUpperCase() !== 'GET') {
        options.data = data;
    }

    if (!isEmpty(headers)) {
        options.headers = { ...options.headers, ...headers };
    }

    return api(options);
}

function fetchApiAuth(opts) {
    return new Promise((resolve, reject) => {
        if (isUndefined(getAuthKey())) {
            reject(new Error('not-authorised'));
        } else {
            const authDate = new Date();
            lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
            resolve(fetchApi(opts, getAuthHeaders()));
        }
    });
}

export {
    getApiUrl,
    getBaseUrl,
    locationData,
    formatParams,

    // -- Auth Key Helpers --//
    getAuthKey,
    getAuthHeaders,
    getTokenExpires,
    getCustomerIdFromCookie,
    getWordpressUserIdFromCookie,
    getUserRoleAndPermissionsFromCookie,

    fetchApi,
    fetchApiAuth,
};

import React from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import ContactForm from '../../../ContactForm';

const AddContactModal = ({ showModal, setShowModal }) => (
    <Modal
        title="Add a new contact"
        okText="Add contact"
        footer={null}
        onCancel={() => setShowModal(false)}
        open={showModal}>
        <ContactForm record={null}
            onSubmit={() => {
                setShowModal(false);
            }}
            goBack={() => {
                setShowModal(false);
            }} />
    </Modal>
);

AddContactModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
};

export default AddContactModal;

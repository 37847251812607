import React, { useEffect, useState } from 'react';
import { Select, Button, Spin, Row, Col, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AddSiteModal from './AddSiteModal';
import AddContactModal from './AddContactModal';
import { getCustomerContactsRequest, getCustomerSitesRequest } from '../../../core/account/accountActions';

const DeliveryDetails = () => {
    const dispatch = useDispatch();
    const {
        customerSites,
        isFetchingCustomerSites,
        customerContacts,
        isFetchingCustomerContacts,
    } = useSelector(state => state.account);
    const [showAddSiteModal, setShowAddSiteModal] = useState(false);
    const [showAddContactModal, setShowAddContactModal] = useState(false);

    const onChangeSite = (val) => {
        document.getElementById('address_id').value = val;
    };

    const onChangeContact = (val) => {
        document.getElementById('site_user_id').value = val;
    };

    useEffect(() => {
        dispatch(getCustomerSitesRequest());
        dispatch(getCustomerContactsRequest());
    }, []);

    return (
        <>
            {!isFetchingCustomerSites ? (
                <Row>
                    <Col span={9}>Site</Col>
                    <Col span={13}>
                        <Form.Item required>
                            <Select defaultValue="Select a site.." style={{ width: '100%' }} onChange={(val) => onChangeSite(val)}>
                                {customerSites.map(site => (
                                    <Select.Option value={site.ADR_ID}>{site.ADR_NAME}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Button onClick={() => setShowAddSiteModal(true)}>+</Button>
                        <AddSiteModal setShowModal={setShowAddSiteModal} showModal={showAddSiteModal} />
                    </Col>
                </Row>
            ) : <Spin />}
            {!isFetchingCustomerContacts ? (
                <Row>
                    <Col span={9}>Contact</Col>
                    <Col span={13}>
                        <Form.Item required>
                            <Select defaultValue="Select a contact.." style={{ width: '100%' }} onChange={(val) => onChangeContact(val)}>
                                {customerContacts.map(contact => (
                                    <Select.Option value={contact.id}>{contact.firstName} {contact.lastName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Button onClick={() => setShowAddContactModal(true)}>+</Button>
                        <AddContactModal setShowModal={setShowAddContactModal} showModal={(showAddContactModal)} />
                    </Col>
                </Row>
            ) : <Spin />}
        </>
    );
};

export default DeliveryDetails;

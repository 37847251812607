import React from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import UpdateSite from '../../../UpdateSite';

const AddSiteModal = ({ showModal, setShowModal }) => (
    <Modal
        title="Add site"
        okText="Add Site"
        footer={null}
        onCancel={() => setShowModal(false)}
        open={showModal}>
        <UpdateSite onActionComplete={() => setShowModal(false)} />
    </Modal>
);

AddSiteModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
};

export default AddSiteModal;
